<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="공사현장 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && popupParam.id && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="setRevision" />
                <c-btn
                  v-show="editable && popupParam.id && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="cancelRevision" />
                <c-btn
                  v-if="editable && popupParam.id"
                  :url="permitUrl"
                  :isSubmit="isSubmit"
                  :param="usePlan"
                  mappingType="PUT"
                  label="제출"
                  icon="check"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="usePlan"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-plant 
                  :disabled="true"
                  :editable="editable"
                  name="plantCd" 
                  v-model="usePlan.plantCd" />
              </div>
              <div class="col-3">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  type="number"
                  label="안전보건관리비 예산"
                  name="col1"
                  v-model="usePlan.col1">
                </c-text>
              </div>
              <div class="col-6">
                <c-datepicker
                  :disabled="true"
                  :editable="editable"
                  :range="true"
                  label="공사기간"
                  name="col2"
                  v-model="usePlan.col2"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-12">
          <c-table
            ref="table"
            title="항목별 사용계획"
            tableId="usePlanItem"
            :columns="grid.columns"
            :data="usePlan.usePlanItems"
            gridHeight="500px"
            :columnSetting="false"
            :expandAll="false"
            :isFullScreen="false"
            :usePaging="false"
            :hideBottom="true"
            :filtering="false"
            @table-data-change="tableDataChange"
          >
          
            <template v-slot:suffixTitle>
              <font v-if="checkCol2" color="#C10015">
                ※ {{lackCol2Text}}
              </font>
            </template>
          </c-table>
        </div>
        <div class="col-12">
          <!-- v-if="popupParam.psiInterLockId" -->
          <c-card title="개정" class="cardClassDetailForm" topClass="topcolor-orange" >
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="row">
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="usePlan.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="usePlan.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="usePlan.regUserName">
                    </c-text>
                  </div>
                  <div class="col-12">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="usePlan.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="usePlan.revs"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'use-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      mappingType: 'POST',
      detailUrl: '',
      saveUrl: '',
      permitUrl: '',
      editable: true,
      isSave: false,
      isSubmit: false,
      isRevision: false,
      usePlan: {
        id: '',  
        plantCd: '',  
        col1: 100000000,
        col2: ['2021-01-01', '2022-12-31'],
        revisionNum: 'v2.0',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        usePlanItems: [
          { 
            id: 1, col2: null, col3: null,
            col1: '안전관리자 등 인건비 및 각종 업무수당 등',
          },
          { 
            id: 2, col2: null, col3: null,
            col1: '안전시설비 등',
          },
          { 
            id: 3, col2: null, col3: null,
            col1: '개인보호구 및 안전장구 구입비 등',
          },
          { 
            id: 4, col2: null, col3: null,
            col1: '사업장의 안전진단비',
          },
          { 
            id: 5, col2: null, col3: null,
            col1: '안전보건교육비 및 행사비 등',
          },
          { 
            id: 6, col2: null, col3: null,
            col1: '근로자의 건강관리비 등',
          },
          { 
            id: 7, col2: null, col3: null,
            col1: '기술지도비',
          },
          { 
            id: 8, col2: null, col3: null,
            col1: '본사 사용비',
          },
        ],
        revs: [
          {
            revisionNum: 'v2.0',
            revisionContent: '개정사유',
            regDtStr: '2022-01-01',
            regUserName: '김길동',
          },
          {
            revisionNum: 'v1.0',
            revisionContent: '제정',
            regDtStr: '2021-01-01',
            regUserName: '김길동',
          },
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '항목',
            align: 'left',
            style: 'width: 400px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '사용계획금액',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
            type: 'cost'
          },
          {
            name: 'col3',
            field: 'col3',
            label: '비율(%)',
            style: 'width: 80px',
            align: 'right',
            sortable: false,
            type: 'number'
          },
          {
            name: 'attach',
            field: 'attach',
            label: '항목별 첨부',
            align: 'center',
            style: 'width: 400px',
            type: 'attach',
            taskClassCd: 'SMF_USE_PLAN_ITEM',
            keyText: 'id',
            sortable: false,
          },
        ],
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  computed: {
    checkCol2() {
      return this.usePlan.col1 !== this.$_.sum(this.$_.map(this.usePlan.usePlanItems, 'col2'))
    },
    lackCol2Text() {
      let interval = this.usePlan.col1 - this.$_.sum(this.$_.map(this.usePlan.usePlanItems, 'col2'))
      return '안전보건관리비 총 예산 ' + this.cuToThousandFilter(this.usePlan.col1) + ' 보다 ' + this.cuToThousandFilter(interval) +
        (interval > 0 ? ' 부족합니다.' : ' 많습니다.')
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.pcd.interlock.get.url;
      this.saveUrl = transactionConfig.psi.pcd.interlock.update.url;
      this.permitUrl = transactionConfig.psi.pcd.interlock.delete.url;
      this.getData(this.popupParam.id);
    },
    getData(id) {
      this.isRevision = false;
      if (id) {
        this.getDetail(id, true);
      }
    },
    getDetail(_id) {
      this.$http.url = this.$format(this.detailUrl, _id);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.usePlan, _result.data)
        this.usePlan.regDtStr = this.usePlan.regDtStr.slice(0, 19)
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'col3') {
        if (props.row['col3'] && props.row['col3'] > 0) {
          props.row['col2'] = this.usePlan.col1 * (props.row['col3'] / 100)
        } else {
          props.row['col2'] = 0
        }
      }
    },
    setRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {
        revisionNum: this.usePlan.revisionNum
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.usePlan.revisionNum = _result.data;
        this.usePlan.revisionContent = '';
        this.usePlan.regDtStr = '';
        this.usePlan.regUserName = '';
        this.isRevision = true;
      });
    },
    cancelRevision() {
      this.getData();
    },
    rowClickRev(row) {
      if (row.psiInterLockId != this.data.psiInterLockId) {
        this.getData(row.psiInterLockId);
      }
    },
    saveData() {
      window.getApp.$emit('ALERT', {
        title: '안내',
        message: '작업중입니다.',
        type: 'warning', // success / info / warning / error
      });
      // if (this.popupParam.id) {
      //   this.saveUrl = this.updateUrl;
      //   this.mappingType = 'PUT';
      // } else {
      //   this.saveUrl = this.insertUrl;
      //   this.mappingType = 'POST';
      // }
      // let saveMsg = '저장하시겠습니까?';
      // if (this.isRevision) {
        // saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
        // 현재버전을 개정하여 저장하시겠습니까?
      // }
      // this.$refs['editForm'].validate().then(_result => {
      //   if (_result) {
      //     window.getApp.$emit('CONFIRM', {
      //       title: '확인',
      //       message: saveMsg,
      //       
      //       type: 'info', // success / info / warning / error
      //       // 확인 callback 함수
      //       confirmCallback: () => {
      //         this.data.regUserId = this.$store.getters.user.userId;
      //         this.data.chgUserId = this.$store.getters.user.userId;
      //         // 개정시
      //         if (this.isRevision) {
      //           this.saveUrl = this.insertUrl;
      //           this.mappingType = 'POST';
      //           this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
      //         }
      //         this.isSave = !this.isSave;
      //       },
      //       // 취소 callback 함수
      //       cancelCallback: () => {
      //       },
      //     });
      //   } else {
      //     window.getApp.$emit('APP_VALID_ERROR');
      //   }
      // });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiInterLockId = result.data.psiInterLockId
      this.getData();
    },
    cuToThousandFilter(num) {
      let _num = (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
      return _num == 0 ? num : _num
    }
  }
};
</script>
